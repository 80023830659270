<template>
    <section class="component-container hero-section w-full h-full">
        
        <picture class="hero-image">
            <source media="(min-width: 820px)" srcset="../assets/ttf-book.png">
            <source media="(max-width: 819px)" srcset="../assets/ttf-book-mobile.png">
            <img src="../assets/ttf-book.png" alt="A book image used for decorative purposes">
        </picture>

        <div class="hero-text-container col-start-6 col-end-12">
            <h1 class="hero-text-container__title">{{ $t('heroTitle') }}</h1>
            <p class="hero-text-container__text">{{ $t('heroText') }}</p>
            <a href="https://platform.thetalentsfactory.com" class="hero-text-container__link"> {{ $t('heroButton1') }} </a>
        </div>
    </section>
            
</template>

<script>
export default {
  name: 'TTFHero',
}
</script>

<style scoped>

section.hero-section{
    position: relative;
    top: var(--header-height);
    height: 90vh;
    margin-bottom: 7rem;
}

.hero-text-container{
    display: flex;
    flex-direction: column;
    margin-top: var(--header-height);
    margin-left: 2rem;
}

.hero-image{
    grid-column: 2 / 6;
    }

.hero-image img{
    max-width: 51.6rem;
}

h1.hero-text-container__title{
    font-weight: 700;
    font-size: 4.7rem;
    line-height: 110%;
    color: #1F2933;
    margin-bottom: 1.6rem;

}

p.hero-text-container__text{
    font-size: 1.8rem;
    line-height: 150%;
    color: #3E4044;
    width:90%;
}

a.hero-text-container__link{
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.6rem;
    font-weight: 600;
    color: #1F2933;
    border: 2px solid #1F2933;
    border-radius: 2px;
    max-width: 40%;
    padding: 12px 0;
    margin-top: 2.4rem;

    transition: all .2s;
}

a.hero-text-container__link:hover{

    background-color: #1F2933;
    color: white;
}

@media screen and (max-width: 1154px){

    .hero-image img{
        max-width: 40rem;
    }

    h1.hero-text-container__title{
        font-size: 3rem;
    }
}

@media screen and (max-width: 1014px){

    .hero-image img{
        max-width: 35rem;
    }
      a.hero-text-container__link{
          max-width: unset;
          width: 70%;
      }
}
    
@media screen and (max-width: 840px) {
  section.hero-section{
    height: auto;
    margin-bottom: var(--header-height);
  }

  .hero-text-container{
      margin-top: unset;
  }

  .hero-image img{
    max-width: 16.3rem;
    margin-top: 2rem;
    align-self: center;
}

  h1.hero-text-container__title{
      font-size: 3.2rem;
      line-height: 110%;
      text-align: center;
  }

  p.hero-text-container__text{
      font-size: 1.6rem;
      line-height: 150%;
      text-align: center;
  }

  a.hero-text-container__link{
      font-size: 16px;
      align-self: center;
      padding-left: 2.5rem;
      padding-right: 2.5rem;
  }
}

</style>
