<template>
  <article class="global-container">
    <ttf-firms-hero></ttf-firms-hero>

    <firmsAdvantages></firmsAdvantages>

    <content-row
      id="what-we-do"
      contentPlacement="left"
      :title="$t('firmsSectionDefinitionTitle')"
      :text="$t('firmsSectionDefinitionText')"
      imageURL="image-4.png"
      imageAlt="Illustration"
    ></content-row>
    <content-row
      id="opportunities"
      contentPlacement="right"
      :title="$t('firmsSectionAdvantagesTitle')"
      :text="$t('firmsSectionAdvantagesText')"
      imageURL="image-5.png"
      imageAlt="Illustration"
      mobileReversed="true"
    ></content-row>


    <content-row
      id="contact"
      contentPlacement="left"
      :title="$t('firmsSectionContactTitle')"
      :text="$t('firmsSectionContactText')"
      :linkText="$t('firmsSectionContactText2')"
      linkUrl="mailto:info@thetalentsfactory.com?subject=Information Request"
      imageURL="image-7.png"
      imageAlt="Illustration"
    ></content-row>
  </article>
</template>

<script>
import contentRow from "../components/contentRow.vue";
import firmsAdvantages from "../components/firmsAdvantages.vue"
import ttfFirmsHero from "../components/ttfFirmsHero.vue";

export default {
  name: "Home",
  components: {
    ttfFirmsHero,
    contentRow,
    firmsAdvantages,
  },
};
</script>

<style scoped>

</style>