<template>
  <article class="global-container">
    <ttf-hero></ttf-hero>

    <section id="advantages" class="home-icons">
      <div class="smaller-container">
        <div class="component-container items-start">
          <icon-text
            class="col-start-1 col-end-4"
            :title="$t('iconTitle1')"
            :text="$t('iconText1')"
            imageURL="icon-1.png"
          ></icon-text>
          <icon-text
            class="col-start-4 col-end-7"
            :title="$t('iconTitle2')"
            :text="$t('iconText2')"
            imageURL="icon-2.png"
          ></icon-text>
          <icon-text
            class="col-start-7 col-end-10"
            :title="$t('iconTitle3')"
            :text="$t('iconText3')"
            imageURL="icon-3.png"
          ></icon-text>
          <icon-text
            class="col-start-10 col-end-13"
            :title="$t('iconTitle4')"
            :text="$t('iconText4')"
            imageURL="icon-4.png"
          ></icon-text>
        </div>
      </div>
    </section>

    <content-row id="what-we-do"
      contentPlacement="right"
      :title="$t('introSectionTitle')"
      :text="$t('introSectionText')"
      imageURL="image-1.png"
      imageAlt="Illustration"
    ></content-row>
    <content-row id="whyDataScience"
      contentPlacement="left"
      :title="$t('whyDataSciTitle')"
      :text="$t('whyDataSciText')"
      :linkText="$t('whyDataSciLinkText')"
      linkUrl="https://www3.weforum.org/docs/WEF_Future_of_Jobs_2020.pdf#page=30"
      imageURL="image-8.png"
      imageAlt="Illustration"
      mobileReversed="true"
    ></content-row>
    <content-row id="opportunities"
      contentPlacement="right"
      :title="$t('jobGuaranteeSectionTitle')"
      :text="$t('jobGuaranteeSectionText')"
      imageURL="image-2.png"
      imageAlt="Illustration"
    ></content-row>
    <content-row id="contact"
      contentPlacement="left"
      :title="$t('contactSectionTitle')"
      :text="$t('contactSectionText')"
      :linkText="$t('contactSectionText2')"
      linkUrl="mailto:info@thetalentsfactory.com?subject=Information Request'"
      imageURL="image-3.png"
      imageAlt="Illustration"
        mobileReversed="true"

      
    ></content-row>
  </article>
</template>

<script>
import contentRow from "../components/contentRow.vue";

import iconText from "../widgets/iconText.vue";
import TTFHero from "../components/ttfHero.vue";

export default {
  name: "Candidates",
  components: {
    "ttf-hero": TTFHero,
    contentRow,
    iconText,
  },
};
</script>

<style scoped>
@import "../styles/resets.css";
@import "../styles/globals.css";



.home-icons {
  background-image: url("../assets/abstract-web.png"), linear-gradient(to right, rgba(255, 252, 244, 0.7), rgba(255, 252, 244, 0.7));
  background-size: cover;
  background-position: center;
  width: 100vw;
  position: relative;
  left: calc((var(--site-single-padding) + 10px) * -1);
  overflow-x: hidden;
}

.smaller-container {
  width: var(--site-width);
  margin: 0 auto;
  display: flex;
  align-items: center;
  height: 100%;
  padding-top: 9rem;
  padding-bottom: 9rem;
}

@media screen and (max-width: 840px) {
  .home-icons{
    height: auto;
    position: relative;
    margin-top: 10rem;
    margin-bottom: 7rem;

    background-image: url("../assets/abstract-mobile.png"), linear-gradient(to right, rgba(255, 252, 244, 0.7), rgba(255, 252, 244, 0.7));
    background-position: unset;
  }
}
</style>