<template>
  <section
    v-if="contentPlacement === 'right'"
    :class="{ 'mobile-reversed': mobileReversed }"
    class="component-container w-full"
  >
    <div class="col-start-1 col-end-7 image-container">
      <img :alt="imageAlt" :src="require(`../assets/${imageURL}`)" />
    </div>
    <div class="text-content col-start-7 col-end-13">
      <h2 class="text-content__title">
        {{ title }}
      </h2>
      <p class="text-content__text">
        {{ text }}

        <a class="text-content__text" v-if="linkText" :href="linkUrl">{{
          linkText
        }}</a>
      </p>
    </div>
  </section>
  <section
    class="component-container w-full"
    :class="{ 'mobile-reversed': mobileReversed }"
    v-else
  >
    <div class="text-content col-start-1 col-end-6">
      <h2 class="text-content__title">
        {{ title }}
      </h2>
      <p class="text-content__text">
        {{ text }}

        <a class="text-content__text" v-if="linkText" :href="linkUrl">{{
          linkText
        }}</a>
      </p>
    </div>
    <div class="col-start-6 col-end-13 image-container">
      <img :alt="imageAlt" :src="require(`../assets/${imageURL}`)" />
    </div>
  </section>
</template>

<script>
export default {
  name: "ContentRow",
  data() {
    return {};
  },

  props: [
    "title",
    "text",
    "imageURL",
    "imageAlt",
    "contentPlacement",
    "mobileReversed",
    "additionalText",
    "linkText",
    "linkUrl",
  ],
};
</script>

<style scoped>
h2.text-content__title {
  font-weight: 700;
  font-size: 3.2rem;
  line-height: 110%;
  color: #1f2933;
  margin-bottom: 1.6rem;
  max-width: 84%;
}

.text-content__text {
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 160%;
  color: #3e4044;
}

a.text-content__text {
  text-decoration: underline;
  text-underline-offset: 3px;
}

@media screen and (max-width: 840px) {
  section {
    margin-bottom: 4rem;
    text-align: center;
  }

  h2.text-content__title {
    max-width: unset;
  }

  .mobile-reversed {
    flex-direction: column-reverse;
  }
}
</style>
