<template>
    <section id="advantages" class="home-icons">
        <h2 class="text-7xl text-gradient uppercase font-black text-center pt-40">{{$t('firmsSectionIconEntry')}}</h2>
        <div class="smaller-container">
            <div class="component-container items-start">
                <icon-text
                class="col-start-1 col-end-4"
                :title="$t('firmsSectionIconTitle1')"
                :text="$t('firmsSectionIconText1')"
                imageURL="icon-5.png"
                ></icon-text>
                <icon-text
                class="col-start-4 col-end-7"
                :title="$t('firmsSectionIconTitle2')"
                :text="$t('firmsSectionIconText2')"
                imageURL="icon-6.png"
                ></icon-text>
                <icon-text
                class="col-start-7 col-end-10"
                :title="$t('firmsSectionIconTitle3')"
                :text="$t('firmsSectionIconText3')"
                imageURL="icon-7.png"
                ></icon-text>
                <icon-text
                class="col-start-10 col-end-13"
                :title="$t('firmsSectionIconTitle4')"
                :text="$t('firmsSectionIconText4')"
                imageURL="icon-8.png"
                ></icon-text>
            </div>
        </div>
    </section>
</template>

<script>

import iconText from "../widgets/iconText.vue";

export default {
  name: "Home",
  components: {
    iconText,
  },
};
</script>

<style scoped>
.home-icons {
  width: 100vw;
  position: relative;
  left: calc((var(--site-single-padding) + 10px) * -1);
  overflow-x: hidden;
  background-color: #EDEBEA;
}

.text-gradient{
  background: linear-gradient(to right, #0f2027, #203a43, #2c5364); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  color: transparent;
  background-clip: text;
}

.smaller-container {
  width: var(--site-width);
  margin: 0 auto;
  display: flex;
  align-items: center;
  height: 100%;
  padding-top: 9rem;
  padding-bottom: 9rem;
}

@media screen and (max-width: 840px) {
  .home-icons{
    height: auto;
    position: relative;
    margin-top: 10rem;
    margin-bottom: 7rem;
  }
}
</style>