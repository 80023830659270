<template>
    <section class="icon-text-container">
        <img class="icon-text__icon" :src="require(`../assets/${imageURL}`)" alt="">
        <h2 class="icon-text__heading">{{title}}</h2>
        <p class="icon-text__text">{{text}}</p>
    </section>
</template>

<script>
export default {
  name: 'iconText',
  props: ["title", "text", "imageURL"]

}
</script>

<style scoped>

    section.icon-text-container{
        display: flex;
        flex-direction: column;        
    }

    img.icon-text__icon{
        width: 4.8rem;
        height: 4.8rem;
        margin-bottom: 1.6rem;
    }

    h2.icon-text__heading{
        font-weight: 700;
        font-size: 20px;
        line-height: 120%;
        color: #1F2933;
        margin-bottom: 1.6rem;
    }

    p.icon-text__text{
        font-weight: normal;
        font-size: 16px;
        line-height: 150%;
        color: #3E4044;
    }

    @media screen and (max-width: 840px){
        section.icon-text-container{
            text-align: center;
            align-items: center;        
            }
    }

</style>
